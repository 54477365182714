import { Injectable } from '@angular/core';
import { PCacheable } from 'ts-cacheable';
import { AngularJSResourceService } from './angular-js-resource.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private get sessionService(): any {
    return this.ngService?.getResource('SessionService');
  }

  constructor(private ngService: AngularJSResourceService) {}

  public get profileAccessToken(): string {
    return this.sessionService?.getAccessToken() || localStorage.getItem('profile-accessToken');
  }

  public get profileApplicantId(): string {
    return this.sessionService?.getApplicantId() || localStorage.getItem('profile-applicantId');
  }

  public get applicantPrelimInfoFilledOut(): boolean {
    return this.sessionService?.getApplicantPrelimInfoFilledOut() || localStorage.getItem('profile-prelimInfoFilledOut');
  }

  public get profileWfmUserId(): string {
    return localStorage.getItem('profile-wfm-userId');
  }

  public get userApplicantLogin(): string {
    return localStorage.getItem('user-applicant-login');
  }

  public get profileCustomerId(): string {
    return localStorage.getItem('profile-customerId');
  }

  public returnToTaskListOnAllCredentialsUploaded(returnToTaskList: any) {
    this.sessionService?.setReturnToTaskListOnAllCredentialsUploaded(returnToTaskList);
  }

  public returnWhere(returnWhere: any) {
    this.sessionService?.setReturnWhere(returnWhere);
  }

  @PCacheable({ maxAge: 1000 * 60 * 60 }) // caching Promise response
  public refreshSession(): Promise<any> {
    return this.sessionService?.refreshSession();
  }
}
