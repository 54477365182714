import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, ResolveEnd, ResolveStart, Router } from '@angular/router';
import moment from 'moment';
import { combineLatest, EMPTY, mergeMap, Observable, Subscription, takeWhile } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { TosDialogComponent, TOSDialogResponse } from './core/components/tos-dialog/tos-dialog.component';
import { AuthService } from './core/services/auth.service';
import { SessionService } from './core/services/session.service';
import { AcceptCurrentTOSPayload, CurrentTOSVersionBE, TosService } from './core/services/tos.service';
import { TranslationService } from './core/services/translation.service';
import { UxService } from './core/services/ux.service';
import { ActionsDialogComponent } from './shared/modules/dialogs';
import { ActionsDialogData } from './shared/modules/dialogs/dialogs.model';
import { CompanyService } from './shared/services/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  tosHasBeenAccepted = false;

  navigationEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)) as Observable<NavigationEnd>;
  resolveStart$ = this.router.events.pipe(filter((event) => event instanceof ResolveStart)) as Observable<ResolveStart>;
  resolveEnd$ = this.router.events.pipe(filter((event) => event instanceof ResolveEnd)) as Observable<ResolveEnd>;
  tosCheck$ = combineLatest([this.navigationEnd$, this.translationService.activeLanguage$]).pipe(
    takeWhile(() => !this.tosHasBeenAccepted),
    mergeMap(() => {
      this.matDialog.closeAll();
      if (!this.tosHasBeenAccepted && this.authService.isAuth() && !this.sessionService.userApplicantLogin)
        return this.tosService.userHasAcceptedLatestTerms$.pipe(
          mergeMap(([userHasAcceptedLatestTOS, currentTOSVersion]) =>
            this.translationService.getAsyncTranslation(currentTOSVersion.contentTranslationKey).pipe(
              take(1),
              tap((contentTranslation) => {
                if (userHasAcceptedLatestTOS) this.tosHasBeenAccepted = true;
                if (!userHasAcceptedLatestTOS) this.openTOSDialog({ ...currentTOSVersion, content: contentTranslation });
              }),
            ),
          ),
        );
      return EMPTY;
    }),
  );

  constructor(
    private router: Router,
    private tosService: TosService,
    private authService: AuthService,
    private uxService: UxService,
    private sessionService: SessionService,
    private translationService: TranslationService,
    private matDialog: MatDialog,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.initializeSubscriptons();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initializeSubscriptons(): void {
    this.subscriptions.push(
      ...[
        this.resolveStart$.subscribe((event) => this.uxService.loading()),
        this.resolveEnd$.subscribe((event) => this.uxService.loading(false)),
        this.navigationEnd$.subscribe((event: any) => {
          if (event?.urlAfterRedirects?.endsWith('/logout')) this.tosHasBeenAccepted = false;
        }),
        this.tosCheck$.subscribe(),
      ],
    );
  }

  openTOSDialog(currentTOSVersion: CurrentTOSVersionBE): void {
    this.matDialog
      .open(TosDialogComponent, {
        data: currentTOSVersion,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.handleTOSDialogResponse(res);
        }
      });
  }

  handleTOSDialogResponse({ currentTOSVersion, userResponse }: TOSDialogResponse): void {
    if (userResponse !== 'yes') {
      this.authService.logout(this.companyService.customerCode, this.companyService.locationCode);
    } else {
      const acceptCurrentTOSPayload: AcceptCurrentTOSPayload = {
        applicantId: localStorage.getItem('profile-applicantId'), // !
        tosId: currentTOSVersion.id,
        acceptanceDate: moment.utc().format('YYYY-MM-DD HH:mm:ss'), // !
      };
      this.tosService.acceptTOS(acceptCurrentTOSPayload).subscribe((accepted) => {
        this.tosHasBeenAccepted = true;
        if (!accepted.acceptanceDate) {
          this.handleFailedTOSAcceptance();
        }
      });
    }
  }

  handleFailedTOSAcceptance(): void {
    const data: ActionsDialogData = {
      title: this.translationService.getTranslation('server_error_dialog_title'),
      body: [this.translationService.getTranslation('tos_api_error_message')],
      actions: [
        {
          button: {
            text: this.translationService.getTranslation('button_text_okay'),
            theme: 'primary',
            type: 'mat-button',
          },
          name: 'ok',
        },
      ],
    };
    this.matDialog
      .open(ActionsDialogComponent, {
        data,
        hasBackdrop: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res: 'ok' | undefined) => {
        // No action taken.
      });
  }
}
