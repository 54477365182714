import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyService } from 'src/profile-ng/app/shared/services/company.service';
import { JobsService } from '../../../features/jobs/services/jobs.service';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { AuthService } from '../../services/auth.service';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { TalentPlatformSettingsService } from '../../services/talent-platform-settings.service';
import { ToastService } from '../../services/toast.service';
import { UxService } from '../../services/ux.service';

interface NavItem {
  translationKey: string;
  defaultLabel?: string;
  icon: string;
  link?: string;
  hide?: Observable<boolean>;
  target?: string;
  routeAction?: any;
}

@Component({
  selector: 'profile-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent implements OnInit {
  public isDrawerVisible: boolean = false;
  public isMobile$: Observable<boolean>;
  public timeAndExpense = this.buildTimeAndExpense();

  public navBarItems: NavItem[] = [
    {
      icon: 'ri-home-5-line',
      translationKey: 'talent_platform_home_title',
      defaultLabel: 'Home',
      link: '../btp/home',
      target: '_self',
    },
    {
      icon: 'ri-briefcase-4-line',
      translationKey: 'talent_platform_jobs_title',
      defaultLabel: 'Jobs',
      hide: of(!this.talentPlatformSettingsService.getConfigFlag('EnableJobSearch')),
      link: '../btp/jobs',
      target: '_self',
    },
    this.timeAndExpense,
    {
      icon: 'ri-user-3-line',
      translationKey: 'my_profile',
      defaultLabel: 'Profile',
      link: '../btp/profile/start',
      target: '_self',
    },
  ];

  public moreDrawerItems: NavItem[] = [
    {
      icon: 'ri-information-line',
      translationKey: 'self_service_nav_text',
      defaultLabel: 'Service',
      hide: of(!this.talentPlatformSettingsService.getConfigFlag('EnableSelfServiceTaskAssignment')),
      link: '../profile/self-service',
      target: '_self',
    },
    {
      icon: 'ri-book-2-line',
      translationKey: 'resources',
      defaultLabel: 'Resources',
      hide: of(!this.talentPlatformSettingsService.getConfigFlag('enable_resources_tab')),
      link: '../profile/resources',
      target: '_self',
    },
    {
      icon: 'ri-stethoscope-line',
      translationKey: 'healthcare',
      defaultLabel: 'Healthcare',
      hide: of(this.talentPlatformSettingsService.getConfigFlag('disable_healthcare_tab')),
      link: '../profile/healthcare',
      target: '_self',
    },
    {
      icon: 'ri-notification-2-line',
      translationKey: 'notifications',
      defaultLabel: 'Notifications',
      link: null,
      target: '_self',
    },
  ];

  constructor(
    private authService: AuthService,
    private breakpointObserverService: BreakpointObserverService,
    private talentPlatformSettingsService: TalentPlatformSettingsService,
    public uxService: UxService,
    private jobsService: JobsService,
    private applicantService: ApplicantService,
    private toastService: ToastService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
  }

  public handleLogout(): void {
    this.authService.logout(this.companyService.customerCode, this.companyService.locationCode);
  }

  public toggleMoreDrawer(): void {
    this.isDrawerVisible = !this.isDrawerVisible;
  }

  public clearJobSeach() {
    this.jobsService.clearJobSearch();
  }

  public goToSso(context: any) {
    context.applicantService.getBteSsoUrl().subscribe((data: any) => {
      if (data?.ssoUrl.includes('error_message')) {
        context.toastService.error('Something went wrong', 'Please contact your recruiter for assistance.');
      } else {
        setTimeout(() => {
          window.open(data?.ssoUrl, '_blank');
        });
      }
    });
  }

  public buildTimeAndExpense(): NavItem {
    let timeExpense = {
      icon: 'ri-money-dollar-circle-line',
      translationKey: 'btp_time_entry_link_title',
      defaultLabel: 'Time Entry',
      hide: of(!this.talentPlatformSettingsService.getConfigFlag('EnableATSTimeAndExpense')),
      routeAction: this.goToSso,
      link: '',
      target: '',
    };

    if (this.talentPlatformSettingsService.getConfigFlag('EnableATSTimeAndExpenseIFrame')) {
      timeExpense.link = '../btp/timeandexpense';
      timeExpense.target = '_self';
      timeExpense.routeAction = null;
    }
    return timeExpense;
  }
}
